body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.content {
  padding: 2rem;
}

.error-row {
  background-color: #ffc7c78c;
}

.warning-row {
  background-color: #ffffb6;
}

@media only screen and (max-width: 576px) {
  .content {
    padding: 0.75rem 0.5rem;
  }
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  to { transform: scale(1.2); }
}
