.leaflet-container a.leaflet-popup-close-button {
  top: 1rem !important;
  right: 1rem !important;
  font-size: 24px !important;
}

.myCluster {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.myCluster:hover {
  border: 1px solid #dcdcdc;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.length-label {
  background-color: rgba(255, 255, 255, 0.95);
  text-align: center;
  border-radius: 8px;
  padding: 5px;
}

.length-label-marker {
  width: auto !important;
  height: auto !important;
}

.alert-polygon-popup {
  width: 400px;
}

/*leaflet draw controls style mods*/
/*hide leaflet draw default controls*/
.leaflet-draw-section, .leaflet-draw-toolbar {
  display: none
}

/*overrides leaflet draw tooltip style to make them similar to antd ones*/
.leaflet-draw-tooltip {
  font-size: 14px;
  min-width: 32px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.leaflet-popup-content {
  min-height: 170px;
  min-width: 200px;
}

.alert-polygon-popup .leaflet-popup-content {
  width: auto !important;
}


.leaflet-control-attribution {
  display: none !important;
}

div.leaflet-bottom.leaflet-left {
  display: none;
}

.asset-position-marker-popup {
  width: 350px;
  min-height: 270px;
}

.asset-position-preview-marker-popup {
  min-width: 209px;
  min-height: 170px;
}

.alert-epicenter-marker-popup .leaflet-popup-content-wrapper {
  height: 8rem;
  width: 12rem;
}

.get-feature-info-popup {
  width: 330px;
}

.pointer-cursor-enabled {
  cursor: pointer;
}

/*Antd modifications*/
.ant-layout-header {
  z-index: 10000;
}

.ant-layout-sider-zero-width-trigger {
  color: #000 !important;
  box-shadow: 5px 10px 20px -7px rgba(0, 0, 0, 0.1);
}

div#geosearch + div.ant-select-selector {
  flex-grow: 1;
}

.ant-input:focus, .ant-input:hover {
  background-color: #FFFFFF;
}

/*antd modification to header menu*/
ul.ant-menu-sub > li {
  color: rgba(0, 0, 0, 0.88);
}

/*modification to every non submenu item*/
ul > li.ant-menu-overflow-item.ant-menu-item {
  display: flex;
  flex-flow: column;
  align-items: center;
}

ul > li.ant-menu-overflow-item.ant-menu-item > span {
  line-height: 1rem;
  margin: 0 12px;
}

ul > li.ant-menu-overflow-item.ant-menu-item > span:first-child {
  margin-bottom: 5px;
}

ul > li.ant-menu-overflow-item.ant-menu-item > span.ant-menu-title-content {
  line-height: 1rem;
  padding-bottom: 0.5rem;
}

/*modification to submenu items (user profile)*/
ul > li.ant-menu-submenu > div.ant-menu-submenu-title {
  display: flex;
  flex-flow: column;
  align-items: center;
}

ul > li.ant-menu-submenu > div.ant-menu-submenu-title > span {
  line-height: 1rem;
  margin: 0 10px;
}

ul > li.ant-menu-submenu > div.ant-menu-submenu-title > span.ant-menu-title-content {
  padding-bottom: 0.5rem;
}

ul.ant-menu {
  align-items: flex-end;
}

ul > li.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal {
  height: 50%;
}

.ql-snow .ql-picker.ql-variables .ql-picker-label[data-value='event_name']::before,
.ql-snow .ql-picker.ql-variables .ql-picker-item[data-value='event_name']::before {
  content: 'Nome evento';
  width: 130px;
}

.ql-snow .ql-picker.ql-variables .ql-picker-label[data-value='event_id']::before,
.ql-snow .ql-picker.ql-variables .ql-picker-item[data-value='event_id']::before {
  content: 'Id evento';
  width: 130px;
}

.ql-snow .ql-picker.ql-variables .ql-picker-label[data-value='event_date']::before,
.ql-snow .ql-picker.ql-variables .ql-picker-item[data-value='event_date']::before {
  content: 'Data evento';
  width: 130px;
}

.ql-snow .ql-picker.ql-variables .ql-picker-label[data-value='event_position']::before,
.ql-snow .ql-picker.ql-variables .ql-picker-item[data-value='event_position']::before {
  content: 'Posizione evento';
  width: 130px;
}

.custom-icon:hover {
  fill: #4096ff !important;
}

.climate-change__table th{
    vertical-align: top;
}